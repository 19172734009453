<template>
  <div class="card p-shadow-6">
    <h1>Surveys - por fazer</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <SurveyChangeSurveyResponsible
      :showToast="true"
      :show="patChangeSurveyResponsible.show"
      :patId="patChangeSurveyResponsible.id"
      :currentRespId="patChangeSurveyResponsible.technicianUserId"
      v-on:cancel="changeSurveyResponsibleCancel"
      v-on:setSurveyResponsible="changedSurveyResponsible"
    />
    <SurveyChangeSurveySeller
      :showToast="true"
      :show="patChangeSurveySeller.show"
      :patId="patChangeSurveySeller.id"
      :currentSellerId="patChangeSurveySeller.sellerId"
      v-on:cancel="changeSurveySellerCancel"
      v-on:setSurveySeller="changedSurveySeller"
    />
    <DataTable
      scrollHeight="65vh"
      :value="patsList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="patsList.length"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} surveys"
      sortField="id"
      :sortOrder="1"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column field="id" header="Pat" sortable filterField="id">
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="resume" header="Resumo" filterField="resume">
        <template #body="slotProps">
          {{ slotProps.data.resume }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="seller" header="Vendedor" sortable filterField="seller">
        <template #body="slotProps">
          {{ slotProps.data.seller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="date_date"
        header="Survey Data"
        sortable
        filterField="date_date"
        dataType="date"
      >
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column
        field="technician"
        header="Téc. Resp. Survey"
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="to_do" header="Estado" filterField="to_do">
        <template #body="slotProps">
          {{ slotProps.data.to_do }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="problemsList"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="lastComment"
        header="Comentário"
        sortable
        filterField="lastComment"
      >
        <template #body="slotProps">
          {{ slotProps.data.lastComment }}
        </template>
      </Column>
      <Column :headerStyle="{ width: '140px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver informação do Pat'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-mr-2"
            @click="viewPatInfo(slotProps.data.id)"
          />
          <Button
            v-if="
              slotProps.data.to_do == 'Por realizar' ||
                slotProps.data.to_do == 'Por marcar' ||
                slotProps.data.to_do == 'Marcado'
            "
            v-tooltip.left="'Alterar data do survey'"
            icon="pi pi-calendar"
            class="p-button-rounded p-button-help p-button-outlined p-mr-2"
            @click="changeSurveyDate(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Por realizar'"
            v-tooltip.left="'Marcar como Realizado'"
            icon="pi pi-check"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmRealizedPat(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Sem relatório'"
            v-tooltip.left="'Inserir Relatório'"
            icon="pi pi-upload"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="showUploadForm(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Por concluir'"
            v-tooltip.left="'Concluir Survey'"
            icon="pi pi-lock"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmClosePat(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == '1. Em Progresso'"
            v-tooltip.left="'Marcar survey como Pendente'"
            icon="pi pi-clock"
            class="p-button-rounded p-button-warning p-button-outlined p-mr-2"
            @click="confirmSetPending(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == '2. Pendente'"
            v-tooltip.left="'Retirar survey de pendente'"
            icon="pi pi-play"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmSetInProgress(slotProps.data)"
          />
          <Button
            v-if="
              loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.technicianUserId
            "
            v-tooltip.left="'Trocar Técnico Responsável pelo Survey'"
            icon="pi pi-users"
            class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
            @click="changeSurveyResposible(slotProps.data)"
          />
          <Button
            v-if="loggedUser.isEsa"
            v-tooltip.left="'Trocar Vendedor do Survey'"
            icon="pi pi-briefcase"
            class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
            @click="changeSurveySeller(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Relatório'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="missForm"
        class="p-col-12"
        @submit.prevent="uploadReportSubmit"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <p><b>Nome do survey: </b>{{ reportToPat.name }}</p>
            <FileUpload
              ref="uploadInput"
              mode="basic"
              name="files[]"
              chooseLabel="Adicionar Relatório"
              v-validate="reportToPat.solution == '' ? 'required' : ''"
            />
          </div>
          <div class="p-field p-col-12 p-md-12">OU</div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label p-mt-1">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="reportToPat.solution"
                v-validate="solutionAreRequired ? 'required' : ''"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('survey-solution')
                  },
                  'form-control'
                ]"
                :name="'survey-solution'"
              />
              <label for="survey-solution">Escrever Relatório</label>
            </span>
          </div>
          <small
            v-if="errors.has('survey-solution') || errors.has('files[]')"
            class="p-error"
            role="alert"
          >
            É obrigatório anexar um ficheiro OU inserir o relatório manualmente
          </small>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="uploadReportSubmit"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showSetDateForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar data do Survey'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <b>Pat: {{ patToSetDate.id }} - </b> {{ patToSetDate.name }} |
      {{ patToSetDate.resume }}
      <form
        name="setDateForm"
        class="p-col-12"
        @submit.prevent="setPatDate"
        style="min-height: 15vh"
      >
        <div class="p-d-flex p-jc-center">
          <v-date-picker
            v-model="patToSetDate.date_date"
            :min-date="new Date()"
            :masks="{
              input: 'YYYY-MM-DD'
            }"
          >
          </v-date-picker>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPatDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeSetDateForm"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showFormChangeStatus"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Colocar Survey como Pendente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="changeTopending"
        class="p-col-12"
        @submit.prevent="savePendingStatus"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <p><b>Pat:</b> {{ patChangeChangeStatus.id }}</p>
            <p><b>Nome:</b> {{ patChangeChangeStatus.name }}</p>
            <span class="p-float-label">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="patChangeChangeStatus.lastComment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component'
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
            <small v-if="errors.has('comment')" class="p-error" role="alert">
              Comentário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-outlined p-button-success"
          @click="savePendingStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined p-button-danger"
          @click="closeFormChangeStatus"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import patsService from "../services/pats.service";
import filesService from "../services/files.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate } from "../helpers/helpers";
import SurveyChangeSurveyResponsible from "../components/SurveyChangeSurveyResponsible";
import SurveyChangeSurveySeller from "../components/SurveyChangeSurveySeller";

export default {
  name: "SurveysTodo",
  components: {
    SurveyChangeSurveyResponsible,
    SurveyChangeSurveySeller
  },
  data() {
    return {
      loading: true,
      patsList: [],
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        to_do: { value: null, matchMode: FilterMatchMode.EQUALS }
      },
      showForm: false,
      reportToPat: {},
      showSetDateForm: false,
      patToSetDate: {},
      patChangeResponsible: {},
      showFormChangeStatus: false,
      patChangeChangeStatus: {},
      patChangeSurveyResponsible: { show: false, id: null, technicianUserId: 0 },
      patChangeSurveySeller: { show: false, id: null, sellerId: 0 },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
    problemsList() {
      let problems = [
        "Por marcar",
        "Marcado",
        "Pendente",
        "Por realizar",
        "Sem relatório"
      ];
      if (
        this.$store.state.auth.user.isAdmin ||
        this.$store.state.auth.user.isEsa
      ) {
        problems.push("Por concluir");
        return problems;
      }
      return problems;
    }
  },
  created() {
    this.loading = true;
    if (this.$route.params.status) {
      if (this.problemsList.includes(this.$route.params.status)) {
        this.filters.to_do.value = this.$route.params.status;
      }
    }
    this.getPats();
  },
  methods: {
    getPats() {
      this.loading = true;
      let userId = this.$store.state.auth.user.id;
      return patsService.getPatsToCloseList(userId).then(response => {
        response = response.map(v => ({
          ...v,
          date_date: new Date(`${v.date} 00:00`)
        }));
        this.loading = false;
        return (this.patsList = response);
      });
    },
    confirmRealizedPat(pat) {
      let message = `Tem a certeza que pretende marcar como realizado o ${pat.service_type}
      do Pat ${pat.id} - ${pat.name}?`;
      this.$confirm.require({
        header: `Marcar como realizado ${pat.service_type}`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          this.realizadPat(pat);
        }
      });
    },
    realizadPat(pat) {
      const status = "2. Realizado";
      const areYou = patAux => {
        return patAux.id == pat.id;
      };

      return patsService
        .updatedStatus(pat.id, { status: status })
        .then(response => {
          if (!response || response.status != status) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao marcar como realizado o ${pat.service_type}`,
              detail: "",
              life: 3000
            });
          }

          let patndex = this.patsList.findIndex(areYou);

          this.patsList[patndex].status = status;
          this.patsList[patndex].to_do = "Sem relatório";

          return this.$toast.add({
            severity: "success",
            summary: `${pat.service_type} realizado`,
            detail: `${pat.service_type} com o pat ${pat.id}
            mudou o estado para realizado`,
            life: 3000
          });
        });
    },
    showUploadForm(pat) {
      this.showForm = true;
      this.reportToPat = pat;
      this.reportToPat["files"] = [];
    },
    closeForm() {
      this.showForm = false;
      this.reportToPat = {};
    },
    uploadReportSubmit() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid && this.$refs.uploadInput.files.length == 0) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.$refs.uploadInput.files.length > 0) {
          this.uploadReportFile();
        }
        if (this.reportToPat.solution != "") {
          this.setSolutions();
        }
      });
    },
    uploadReportFile() {
      const areYou = patAux => {
        return patAux.id == this.reportToPat.id;
      };
      let formData = new FormData();
      formData.append("file", this.$refs.uploadInput.files[0]);
      formData.append("origin", "PA");
      formData.append("origin_reference", this.reportToPat.reference);

      return filesService.addFile(formData).then(response => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir o relatório",
            life: 3000
          });
        }

        let patndex = this.patsList.findIndex(areYou);
        if (patndex >= 0) {
          this.patsList.splice(patndex, 1);
        }
        this.closeForm();

        return this.$toast.add({
          severity: "success",
          summary: "Relatório inserido",
          detail: "O relatório inserido com sucesso",
          life: 3000
        });
      });
    },
    setSolutions() {
      const areYou = patAux => {
        return patAux.id == this.reportToPat.id;
      };

      return patsService
        .setSolution(this.reportToPat.id, {
          solution: this.reportToPat.solution
        })
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o relatório",
              life: 3000
            });
          }

          let patndex = this.patsList.findIndex(areYou);
          if (patndex >= 0) {
            this.patsList.splice(patndex, 1);
          }
          this.closeForm();

          return this.$toast.add({
            severity: "success",
            summary: "Relatório inserido",
            detail: "O relatório inserido com sucesso",
            life: 3000
          });
        });
    },
    solutionAreRequired() {
      if (this.$refs.uploadInput.files.length == 0) {
        return true;
      }

      return false;
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`
      });
      window.open(route.href, "_blank");
    },
    confirmClosePat(pat) {
      let message = `Tem a certeza que pretende concluir ${pat.service_type}
      do Pat ${pat.id} - ${pat.name}?`;
      this.$confirm.require({
        header: `Concluir ${pat.service_type}`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          this.closePat(pat);
        }
      });
    },
    closePat(pat) {
      const areYou = patAux => {
        return patAux.id == pat.id;
      };

      return patsService.closePat(pat.id).then(response => {
        if (!response || response.closed != 1) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao concluir ${pat.service_type}`,
            detail: "",
            life: 3000
          });
        }

        let patndex = this.patsList.findIndex(areYou);

        if (patndex >= 0) {
          this.patsList.splice(patndex, 1);
        }

        return this.$toast.add({
          severity: "success",
          summary: `${pat.service_type} concluido`,
          detail: `${pat.service_type} com o pat ${pat.id}
            foi concluido`,
          life: 3000
        });
      });
    },
    closeSetDateForm() {
      this.showSetDateForm = false;
      this.patToSetDate = {};
    },
    changeSurveyDate(pat) {
      this.patToSetDate = pat;
      this.showSetDateForm = true;
    },
    setPatDate() {
      let bodyParams = {
        date: getOnlyDate(this.patToSetDate.date_date)
      };
      return patsService
        .setSurveyDate(this.patToSetDate.id, bodyParams)
        .then(response => {
          if (!response) {
            this.patToSetDate.date_date = new Date(
              `${this.patToSetDate.date} 00:00`
            );
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao ao alterar a data",
              life: 3000
            });
          } else {
            this.patToSetDate.date = bodyParams.date;
            this.$toast.add({
              severity: "success",
              summary: "Data alterada",
              detail: "A data foi alterada com sucesso",
              life: 3000
            });
          }

          this.closeSetDateForm();
        });
    },
    closeFormChangeStatus() {
      this.$validator.pause();
      this.patChangeChangeStatus.lastComment = null;
      this.patChangeChangeStatus = {};
      this.showFormChangeStatus = false;
    },
    confirmSetPending(pat) {
      this.patChangeChangeStatus = pat;
      this.showFormChangeStatus = true;
    },
    savePendingStatus() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = { message: this.patChangeChangeStatus.lastComment };
        return patsService
          .setPending(this.patChangeChangeStatus.id, bodyParams)
          .then(response => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado do survey`,
                detail: "",
                life: 3000
              });
            }
            this.patChangeChangeStatus.status = "2. Pendente";
            this.patChangeChangeStatus.to_do = "Pendente";
            this.patChangeChangeStatus.lastComment = response;
            this.$toast.add({
              severity: "success",
              summary: `O survey mudou o estado para pendente`,
              detail: "",
              life: 3000
            });
            return this.closeFormChangeStatus();
          });
      });
    },
    confirmSetInProgress(pat) {
      let title = `Retirar survey de pendente?`;
      let message = `Tem a certeza que pretende remover o pat ${pat.id} - ${pat.name} do estado de pendente?`;

      this.$confirm.require({
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          return patsService.removePending(pat.id).then(response => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado do survey`,
                detail: "",
                life: 3000
              });
            }
            pat.status = "1. Em Progresso";
            pat.to_do = "Por realizar";
            pat.lastComment = null;
            return this.$toast.add({
              severity: "success",
              summary: `O survey mudou o estado para em progresso`,
              detail: "",
              life: 3000
            });
          });
        }
      });
    },
    changeSurveyResposible(pat) {
      this.patChangeSurveyResponsible.show = true;
      this.patChangeSurveyResponsible.id = pat.id;
      this.patChangeSurveyResponsible.technicianUserId = pat.technicianUserId;
      this.patChangeSurveyResponsible.pat = pat;
    },
    changeSurveyResponsibleCancel() {
      this.patChangeSurveyResponsible = {
        show: false,
        id: null,
        technicianUserId: null
      };
    },
    changedSurveyResponsible(response) {
      if (response.status == "ok") {
        this.patChangeSurveyResponsible.pat.technicianUserId =
          response.currentResponsible.id;
        this.patChangeSurveyResponsible.pat.technician =
          response.currentResponsible.name;
      }
      this.patChangeSurveyResponsible = {
        show: false,
        id: null,
        technicianUserId: null
      };
    },
    changeSurveySellerCancel() {
      this.patChangeSurveySeller = {
        show: false,
        id: null,
        sellerId: null
      };
    },
    changedSurveySeller(response) {
      if (response.status == "ok") {
        this.patChangeSurveySeller.pat.sellerId =
          response.currentSeller.id;
        this.patChangeSurveySeller.pat.seller =
          response.currentSeller.name;
      }
      this.patChangeSurveySeller = {
        show: false,
        id: null,
        sellerId: null
      };
    },
    changeSurveySeller(pat) {
      this.patChangeSurveySeller.show = true;
      this.patChangeSurveySeller.id = pat.id;
      this.patChangeSurveySeller.sellerId = parseInt(pat.sellerId);
      this.patChangeSurveySeller.pat = pat;
    },
  }
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100% !important;
}
</style>
